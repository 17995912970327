/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import ChatList from './ChatList'
import ChatView from './ChatView'
import {
  useChatCountReset,
  useChatList,
  useAddNewMsg,
  useLoadActivityTypeList,
  useAddNewChatActivity,
  useInviteChatParticipant,
} from '../../../hooks/activity.hooks'
import { useLoadExistChatCheck } from '../../../hooks/chat.hooks'
import { useSwipeChatInitiate } from '../../../hooks/asset.hooks'
import { getMessageUniqueId, sanitizeContent } from '../../../utils/common.util'
import { getCurrentDateUTC } from '../../../utils/date.utils'
import { useAuthContext } from '../../../context/auth.context'
import { sharedConst } from '../../../utils/action.constant'
import { useSharedContext } from '../../../context/shared.context'
import { Box, Badge, Grid, GridItem } from '@chakra-ui/react'
import { useLanguageContext } from '../../../context/locale.context'
import { useLoadChatCount } from '../../../hooks/asset.hooks'

export const CHAT_PANEL_VIEW = {
  CHAT_VIEW: 'CHAT_VIEW',
  LIST_VIEW: 'LIST_VIEW',
}
const Chat = () => {
  const [chatView, setchatView] = useState(CHAT_PANEL_VIEW.LIST_VIEW)
  const [selectedProfile, setselectedProfile] = useState(null)
  const [chatUnreadCount, setchatUnreadCount] = useState(1)
  const [chatData, setchatData] = useState([])
  const [isNewChat, setisNewChat] = useState(false)
  const [activityTypes, setActivityType] = useState([])
  const [dummy, setdummy] = useState(true)
  const { mutate: loadChatCount } = useLoadChatCount()
  const [isChatLoading, setisChatLoading] = useState(false)
  const onChatClose = () => {
    setchatView(CHAT_PANEL_VIEW.LIST_VIEW)
  }
  const {
    state: { authData },
  } = useAuthContext()
  const userData = authData

  const { mutate } = useChatCountReset()
  const { mutate: getChatData } = useChatList()
  const { mutate: addNewMsg } = useAddNewMsg()
  const { mutate: loadActivityDetails } = useLoadActivityTypeList()
  const { mutate: loadExistChatCheck } = useLoadExistChatCheck()
  const { mutate: swipeChatInitiate } = useSwipeChatInitiate()
  const { mutate: addNewChatActivity } = useAddNewChatActivity()
  const { mutate: inviteChatParticipant } = useInviteChatParticipant()
  const { state, dispatch } = useSharedContext()
  const {
    state: { locale },
  } = useLanguageContext()
  useEffect(() => {
    loadActivityDetails(
      {},
      {
        onSuccess: async data => {
          setActivityType(data)
        },
      }
    )
  }, [])

  const onProfileSelection = user => {
    setisChatLoading(true)
    dispatch({
      type: sharedConst.SELECTED_CHAT_PROFILE,
      selectedValue: user,
    })
    setselectedProfile(user)
    setdummy(!dummy)
    showChat(user)
    mutate(
      {
        asset_message_counter: 0,
        activity_type_category_id: 16,
        activity_id: user.activity_id,
        activity_id_array: `[${user.activity_id}]`,
        message_unique_id: getMessageUniqueId(userData.asset_id),
        track_latitude: 0,
        track_longitude: 0,
        track_altitude: 0,
        track_gps_datetime: getCurrentDateUTC(),
        track_gps_accuracy: 0,
        track_gps_status: 0,
        flag_offline: 0,
        flag_retry: 0,
      },
      {
        onSuccess: async data => { 
          setisChatLoading(false)
        },
      },
      {
        onError: async err => {
          console.log(err)
        },
      }
    )
  }

  const showChat = user => {
    let chatValue = []
    getChatData(
      {
        activity_id: user?.activity_id || 0,
        flag_previous: 0,
        page_limit: 45,
        page_start: chatData?.length === 45 ? 45 : 0,
        activity_type_category_id: user.isGroupChat
          ? 27
          : user.activity_type_category_id,
        datetime_differential: '1970-01-01 00:00:00',
        file_unread_count: 0,
      },
      {
        onSuccess: async data => {
          chatValue = chatValue.concat(data)
          if (data?.length >= 45) {
            getMoreChatList(user, data.length, chatValue)
          } else {
            setchatData(data)
            setdummy(!dummy)
          }
        },
      },
      {
        onError: async err => {
          console.log(err)
        },
      }
    )
  }

  const getMoreChatList = (user, chatcount, chatValue) => {
    getChatData(
      {
        activity_id: user?.activity_id || 0,
        flag_previous: 0,
        page_limit: 45,
        page_start: chatcount || 0,
        activity_type_category_id: user.activity_type_category_id,
        datetime_differential: '1970-01-01 00:00:00',
        file_unread_count: 0,
      },
      {
        onSuccess: async data => {
          chatValue = chatValue.concat(data)
          if (data.length >= 45) {
            getMoreChatList(user, chatValue?.length, chatValue)
          } else {
            setchatData(chatValue)
            setdummy(!dummy)
          }
        },
      }
    )
  }
  const buildTimelineEntryParams = val => {
    let url = val?.url || ''
    let msg = val?.message || ''
    const activityTimelineJson = {
      content: sanitizeContent(msg),
      subject: sanitizeContent(msg),
      mail_body: sanitizeContent(msg),
      attachments: !!url ? [url] : [],
      asset_reference: [],
      activity_reference: [],
      form_approval_field_reference: [],
    }
    const timelineParams = {
      asset_message_counter: 0,
      flag_pin: 0,
      flag_priority: 0,
      flag_offline: 0,
      flag_retry: 0,
      message_unique_id: getMessageUniqueId(userData.asset_id),
      activity_channel_id: 0,
      activity_channel_category_id: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      track_altitude: 0,
      track_gps_datetime: getCurrentDateUTC(),
      track_gps_accuracy: '0',
      track_gps_status: 0,
      activity_type_category_id:
        isNewChat && val.user.isGroupChat
          ? 27
          : isNewChat
            ? 16
            : val.user?.activity_type_category_id,
      activity_type_id: isNewChat ? 0 : val.user?.activity_type_id,
      activity_id:
        // isNewChat
        // ? 0
        // :
        val.user.activity_id,
      activity_parent_id: 0,
      activity_sub_type_id: 0,
      //timeline entry
      activity_timeline_collection: JSON.stringify(activityTimelineJson),
      activity_stream_type_id: 325, //23003,//
      activity_timeline_text: msg,
      activity_timeline_url: '',
      // data_entity_inline: JSON.stringify(activityTimelineJson),
      operating_asset_first_name: userData.operating_asset_first_name,
      datetime_log: getCurrentDateUTC(),
    }
    return timelineParams
  }

  const buildSelectedUserPrams = user => {
    let selectedUser = user
    let tempParams = {}
    if (selectedUser?.isGroupChat) {
      tempParams = selectedUser.userList.map(user => {
        let params = {
          label: user.operating_asset_first_name,
          value: {
            contact_account_id: user.account_id,
            contact_asset_id: user.asset_id,
            contact_asset_type_id: user.asset_type_id,
            contact_department: user.workforce_name,
            contact_designation: user.asset_first_name,
            contact_first_name: user.asset_first_name,
            contact_last_name: user.asset_last_name,
            contact_operating_asset_id: user.operating_asset_id,
            contact_operating_asset_name: user.operating_asset_first_name,
            contact_organization: user.organization_name,
            contact_organization_id: user.organization_id,
            contact_workforce_id: user.workforce_id,
            contact_phone_number: user.operating_asset_phone_number,
          },
        }
        return params
      })
    } else {
      tempParams = {
        label: selectedUser?.operating_asset_first_name,
        value: {
          contact_account_id: selectedUser.account_id,
          contact_asset_id: selectedUser.asset_id,
          contact_asset_type_id: selectedUser.asset_type_id,
          contact_department: selectedUser.workforce_name,
          contact_designation: selectedUser.asset_first_name,
          contact_first_name: selectedUser.asset_first_name,
          contact_last_name: selectedUser.asset_last_name,
          contact_operating_asset_id: selectedUser.operating_asset_id,
          contact_operating_asset_name: selectedUser.operating_asset_first_name,
          contact_organization: selectedUser.organization_name,
          contact_organization_id: selectedUser.organization_id,
          contact_workforce_id: selectedUser.workforce_id,
          contact_phone_number: selectedUser.operating_asset_phone_number,
        },
      }
    }
    return tempParams
  }

  const buildChatExistsParams = user => {
    let currChatUser = buildSelectedUserPrams(user)
    let chatExistsParams = {}
    if (isNewChat) {
      chatExistsParams = {
        // organization_id: this.props.userData.organization_id,
        // account_id: this.props.userData.account_id,
        // workforce_id: this.props.userData.workforce_id,
        // asset_token_auth: this.props.authToken,
        // asset_id: this.props.userData.asset_id,
        target_asset_id: currChatUser.value.contact_asset_id,
        activity_type_category_id: 16,
        asset_type_id: currChatUser.value.contact_asset_type_id,
        auth_asset_id: userData.asset_id,
        // device_os_id: 5,
        // service_version: 1.0,
        // app_version: 1.0,
      }
    } else {
      chatExistsParams = {
        // account_id: this.props.userData.account_id,
        // asset_id: this.props.userData.asset_id,
        // asset_token_auth: this.props.authToken,
        // auth_asset_id: this.props.userData.asset_id,
        creator_asset_id:
          userData.asset_id < currChatUser.value.contact_asset_id
            ? userData.asset_id
            : currChatUser.value.contact_asset_id,
        owner_asset_id:
          userData.asset_id > currChatUser.value.contact_asset_id
            ? userData.asset_id
            : currChatUser.value.contact_asset_id,
        asset_message_counter: 0,
        message_unique_id: getMessageUniqueId(userData.asset_id),
        device_os_id: 5,
        track_latitude: 0.0,
        track_longitude: 0.0,
        track_altitude: 0,
        track_gps_datetime: getCurrentDateUTC(),
        track_gps_accuracy: 0,
        track_gps_status: 0,
        organization_id: userData.organization_id,
        page_limit: 50,
        page_start: 0,
        service_version: '1.0',
        app_version: '2.1.6',
        workforce_id: userData.workforce_id,
      }
    }
    return chatExistsParams
  }

  const buildParticipantSetParams = user => {
    let currChatUser = buildSelectedUserPrams(user)
    const activityType = activityTypes.filter(res => {
      return res.activity_type_category_id === 16
    })

    let chatUser = currChatUser.value
    let addParticipantInlineDataArr = []
    let addParticipantInlineData = {}
    if (Array.isArray(currChatUser)) {
      addParticipantInlineDataArr = currChatUser.map(selUser => {
        return {
          organization_id: selUser.value.contact_organization_id,
          account_id: selUser.value.contact_account_id,
          workforce_id: selUser.value.contact_workforce_id,
          asset_type_id: selUser.value.contact_asset_type_id,
          asset_category_id: 1,
          asset_id: selUser.value.contact_asset_id,
          asset_first_name: selUser.value.contact_first_name,
          operating_asset_first_name:
            selUser.value.contact_operating_asset_name,
          workforce_name: selUser.value.contact_department,
          access_role_id: 1,
          message_unique_id: getMessageUniqueId(selUser.value.contact_asset_id),
        }
      })
    } else {
      addParticipantInlineData.organization_id =
        chatUser.contact_organization_id
      addParticipantInlineData.account_id = chatUser.contact_account_id
      addParticipantInlineData.workforce_id = chatUser.contact_workforce_id
      addParticipantInlineData.asset_type_id = chatUser.contact_asset_type_id
      addParticipantInlineData.asset_category_id = 1
      addParticipantInlineData.asset_id = chatUser.contact_asset_id
      addParticipantInlineData.asset_first_name = chatUser.contact_first_name
      addParticipantInlineData.operating_asset_first_name =
        chatUser.contact_operating_asset_name
      addParticipantInlineData.workforce_name = chatUser.contact_department
      addParticipantInlineData.access_role_id = 1
      addParticipantInlineData.message_unique_id = getMessageUniqueId(
        chatUser.contact_asset_id
      )
      addParticipantInlineDataArr.push(addParticipantInlineData)
    }
    let participentParmas = {
      account_id: userData.account_id,
      activity_id: 0,
      activity_participant_collection: JSON.stringify(
        addParticipantInlineDataArr
      ),
      activity_type_category_id: user.isGroupChat ? 27 : 16,
      activity_type_id: activityType[0].activity_type_id,
      api_version: 1,
      app_version: 1,
      asset_id: userData.asset_id,
      asset_message_counter: 0,
      device_os_id: 5,
      flag_offline: 0,
      flag_pin: 0,
      flag_retry: 0,
      limit_value: 50,
      message_unique_id: getMessageUniqueId(userData.asset_id),
      organization_id: userData.organization_id,
      service_version: 1,
      start_from: 0,
      track_altitude: 0,
      track_gps_accuracy: '0',
      track_gps_datetime: '2020-07-13 15:44:06',
      track_gps_status: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      workforce_id: userData.workforce_id,
    }
    return participentParmas
  }

  const buildCreateChatParams = user => {
    let currChatUser = buildSelectedUserPrams(user)
    let title
    let isGroupChat = false
    if (Array.isArray(currChatUser)) {
      isGroupChat = selectedProfile.isGroupChat
      title = selectedProfile.groupName
    } else {
      title =
        userData.operating_asset_first_name +
        '-' +
        currChatUser.value.contact_operating_asset_name
    }

    const activityType = activityTypes?.filter(res => {
      return res.activity_type_category_id === 16
    })

    let addTaskInlineData = [
      {
        asset_opr_name: userData.operating_asset_first_name,
        asset_id: userData.asset_id,
      },
      {
        asset_opr_name: currChatUser.value.contact_operating_asset_name,
        asset_id: currChatUser.value.contact_asset_id,
      },
    ]

    const params = {
      organization_id: userData.organization_id,
      account_id: userData.account_id,
      workforce_id: isNewChat
        ? userData.workforce_id
        : userData.asset_id < currChatUser.value.contact_asset_id
          ? userData.workforce_id
          : currChatUser.value.contact_workforce_id,
      asset_id: isNewChat
        ? userData.asset_id
        : userData.asset_id < currChatUser.value.contact_asset_id
          ? userData.asset_id
          : currChatUser.value.contact_asset_id,
      // asset_token_auth: this.props.authToken,
      // auth_asset_id: this.props.userData.asset_id,
      asset_message_counter: 0,
      activity_title: title,
      activity_description: isNewChat
        ? isGroupChat
          ? {}
          : JSON.stringify(addTaskInlineData)
        : '',
      activity_inline_data: isGroupChat
        ? {}
        : JSON.stringify(addTaskInlineData),
      activity_datetime_start: getCurrentDateUTC(), //$rootScope.getCurrentDateUTC($scope.startDate),
      activity_datetime_end: getCurrentDateUTC(),
      activity_type_category_id: 16,
      activity_type_id: activityType[0].activity_type_id,
      //activity_access_role_id: 27,
      activity_access_role_id: isNewChat ? 27 : 55,
      asset_participant_access_id: isNewChat ? 27 : 55,
      activity_parent_id: 0,
      flag_pin: 0,
      flag_priority: 0,
      flag_offline: 0,
      flag_retry: 0,
      message_unique_id: getMessageUniqueId(userData.asset_id),
      activity_channel_id: 0,
      activity_channel_category_id: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      track_altitude: 0,
      track_gps_datetime: getCurrentDateUTC(),
      track_gps_accuracy: '0',
      web_version: '1.0.1',
      track_gps_status: 0,
      //tracl_os_id: 5,
      service_version: 1.0,
      app_version: isNewChat ? 5.2 : 1.0,
      device_os_id: 5,
      owner_asset_id: isNewChat
        ? 0
        : userData.asset_id > currChatUser.value.contact_asset_id
          ? userData.asset_id
          : currChatUser.value.contact_asset_id,
      owner_workforce_id: isNewChat
        ? currChatUser.value.contact_workforce_id
        : userData.asset_id > currChatUser.value.contact_asset_id
          ? userData.workforce_id
          : currChatUser.value.contact_workforce_id,
      activity_sub_type_id: 0,
      activity_flag_file_enabled: 0,
      activity_status_id: 0,
      activity_status_type_id: 0,
      activity_status_type_category_id: 0,
      activity_id: 0,
    }
    return params
  }

  const buildGroupChatParams = user => {
    let currChatUser = buildSelectedUserPrams(user)
    let title
    let isGroupChat = false
    if (Array.isArray(currChatUser)) {
      isGroupChat = user.isGroupChat
      title = user.groupName
    } else {
      title =
        userData.operating_asset_first_name +
        '-' +
        currChatUser.value.contact_operating_asset_name
    }

    const activityType = activityTypes.filter(res => {
      return res.activity_type_category_id === 27
    })

    const params = {
      // organization_id: this.props.userData.organization_id,
      // account_id: this.props.userData.account_id,
      workforce_id: isNewChat
        ? userData.workforce_id
        : userData.asset_id < currChatUser[0].value.contact_asset_id
          ? userData.workforce_id
          : currChatUser[0].value.contact_workforce_id,
      asset_id: isNewChat
        ? userData.asset_id
        : userData.asset_id < currChatUser[0].value.contact_asset_id
          ? userData.asset_id
          : currChatUser[0].value.contact_asset_id,
      // asset_token_auth: this.props.authToken,
      // auth_asset_id: this.props.userData.asset_id,
      asset_message_counter: 0,
      activity_title: title,
      activity_description: JSON.stringify({}),
      activity_inline_data: JSON.stringify({}),
      activity_datetime_start: getCurrentDateUTC(), //$rootScope.getCurrentDateUTC($scope.startDate),
      activity_datetime_end: getCurrentDateUTC(),
      activity_type_category_id: 27,
      activity_type_id: activityType[0].activity_type_id,
      activity_access_role_id: 89,
      activity_sub_type_id: 0,
      activity_flag_file_enabled: 0,
      activity_status_id: 0,
      activity_status_type_id: 0,
      activity_status_type_category_id: 0,
      asset_participant_access_id: 89,
      activity_parent_id: 0,
      flag_pin: 0,
      flag_priority: 0,
      flag_offline: 0,
      flag_retry: 0,
      message_unique_id: getMessageUniqueId(userData.asset_id),
      activity_channel_id: 0,
      activity_channel_category_id: 0,
      activity_flag_response_required: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      track_altitude: 0,
      track_gps_datetime: getCurrentDateUTC(),
      track_gps_accuracy: '0',
      track_gps_status: 0,
      service_version: 1.0,
      app_version: isNewChat ? 5.2 : 1.0,
      device_os_id: 5,
      // activity_id: 0,
    }
    return params
  }

  const onMessageSubmit = msg => {
    let message
    if (typeof msg === 'object') {
      message = msg?.message || msg?.url
    } else {
      message = msg
    }
    if (!message) return

    addNewMsg(
      {
        ...buildTimelineEntryParams(msg),
      },
      {
        onSuccess: async data => {
          onProfileSelection(msg.user)
        },
        onError: async err => {
          console.log(err)
        },
      }
    )
  }

  const chatInitializer = user => {
    setisChatLoading(true)
    setselectedProfile(user)
    let currChatUser
    if (isNewChat || user.isGroupChat) {
      currChatUser = buildSelectedUserPrams(user)
      let message = `${userData.operating_asset_first_name} has started conversation`
      if (Array.isArray(currChatUser)) {
        //group chat
        message = `Group Created By ${userData.operating_asset_first_name}`
        addNewChatActivity(
          {
            ...buildGroupChatParams(user),
          },
          {
            onSuccess: async data => {
              setisChatLoading(false)
              let setParticipant = buildParticipantSetParams(user)
              setParticipant.activity_id = data.data.response.activity_id
              inviteChatParticipant({
                ...setParticipant,
              })

              let msg = {
                message,
                user,
              }
              msg.user.activity_id = data.data.response.activity_id
              onMessageSubmit(msg)
              dispatch({ type: sharedConst.REFRESH_CHAT_LIST })
            },
            onError: async err => {
              console.log(err)
            },
          }
        )
      } else {
        //individual chat
        loadExistChatCheck(
          {
            ...buildChatExistsParams(user),
          },
          {
            onSuccess: async data => {
              const chatData = data.data.response
              if (!chatData.length) {
                swipeChatInitiate(
                  {
                    swipe_type_id: 5, // chat initiation
                    operating_asset_first_name:
                      userData.operating_asset_first_name || '',
                    target_asset_id: user.operating_asset_id,
                    target_operating_asset_first_name:
                      user.operating_asset_first_name,
                  },
                  {
                    onError: async err => {
                      console.log(err)
                    },
                  }
                )
                addNewChatActivity(
                  {
                    ...buildCreateChatParams(user),
                  },
                  {
                    onSuccess: async data => {
                      let setParticipant = buildParticipantSetParams(user)
                      setParticipant.activity_id =
                        data.data.response.activity_id
                      inviteChatParticipant({
                        ...setParticipant,
                      })
                      let msg = {
                        message,
                        user,
                      }
                      msg.user.activity_id = data.data.response.activity_id
                      onMessageSubmit(msg)
                      setisChatLoading(false)
                      dispatch({ type: sharedConst.REFRESH_CHAT_LIST })
                    },
                    onError: async err => {
                      console.log(err)
                    },
                  }
                )
              } else {
                // user.activity_id = chatData[0].activity_id
                // onProfileSelection(user)
                // setselectedProfile(chatData[0])
              }
            },
          }
        )
      }
    }
  }

  useEffect(() => {
    loadChatCount(
      {},
      {
        onSuccess: async data => {
          let countArray = (
            data?.filter(
              val =>
                val.activity_type_category_id === 16 ||
                val.activity_type_category_id === 27
            ) || []
          )?.map(val => val.count)
          if (countArray.length) {
            let count = countArray.reduce((a, b) => a + b)
            setchatUnreadCount(count)
          } else {
            setchatUnreadCount(null)
          }
        },

        onError: async err => {
          console.log(err)
        },
      }
    )
  }, [])
  return (
    <div>
      <Box fontSize="lg" fontWeight="800" paddingBottom={'10px'}>
        {chatUnreadCount && (
          <Badge colorScheme="red" marginLeft="2">
            {chatUnreadCount}
          </Badge>
        )}
      </Box>
      <Grid
        templateColumns={
          chatView === CHAT_PANEL_VIEW.LIST_VIEW
            ? 'repeat(1, 1fr)'
            : 'repeat(3, 1fr)'
        }
        gap={2}
      >
        <GridItem colSpan={1} background='#fff' borderRadius='10px'>
          <ChatList
            isFromGroup={false}
            setchatView={setchatView}
            onProfileSelection={onProfileSelection}
            user={userData}
            isNewChat={isNewChat}
            setisNewChat={setisNewChat}
            chatInitializer={chatInitializer}
            setselectedProfile={setselectedProfile}
          />
        </GridItem>
        <GridItem colSpan={2} background='#fff' borderRadius='10px'>
          {chatView !== CHAT_PANEL_VIEW.LIST_VIEW && (
            <ChatView
              setchatView={setchatView}
              selectedProfile={selectedProfile}
              user={userData}
              chatData={chatData}
              setchatData={setchatData}
              onMessageSubmit={onMessageSubmit}
              isNewChat={isNewChat}
              onProfileSelection={onProfileSelection}
              setisNewChat={setisNewChat}
              setselectedProfile={setselectedProfile}
              isChatLoading={isChatLoading}
            />
          )}
        </GridItem>
      </Grid>
    </div>
  )
}

export default Chat
