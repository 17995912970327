/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
  Text,
  Icon,
} from '@chakra-ui/react';
import { VscGraph } from 'react-icons/vsc';
import { useAuthContext } from '../../../context/auth.context';
import { useAssetOverallGScore } from '../../../hooks/asset.hooks';
import moment from 'moment';

export default function Overall() {
  const {
    state: { authData },
  } = useAuthContext();
  const [overallScore, setOverallScore] = useState(0);
  const { mutate } = useAssetOverallGScore();
  useEffect(() => {
    mutate(
      {
        flag: 0,
        summary_id: 7,
      },
      {
        onSuccess: async data => {
          if (data?.length) {
            if (data[0].data_entity_bigint_1) {
              setOverallScore(data[0].data_entity_bigint_1);
            }
          }
        },
      }
    );
  }, []);
  return (
    <Box
      border="none"
      boxShadow="md"
      borderRadius="md"
      mb={2}
      p={2}
      bg={'secondary'}
      color={'black'}
    >
      <Stat>
        <StatLabel>Overall GScore</StatLabel>
        <StatNumber>
          <Flex d={'flex'} justifyContent={'space-between'}>
            <Text color={'brand.800'} fontSize={'32px'}>
              {overallScore}
            </Text>
            <Icon as={VscGraph} h={10} w={10} color="brand.800"/>
          </Flex>
        </StatNumber>
        {/* <StatHelpText>{moment(new Date()).format('MMM YYYY')}</StatHelpText> */}
      </Stat>
    </Box>
  );
}
