/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  Text,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { VscGraph } from 'react-icons/vsc';
import { useAuthContext } from '../../../context/auth.context';
import {
  useAssetMonthlyGScore,
  useAssetMonthlyWorkflowList,
} from '../../../hooks/asset.hooks';
import moment from 'moment';

export default function Monthly() {
  const {
    state: { authData },
  } = useAuthContext();
  const [monthlyScore, setMonthlyScore] = useState(0);
  const [workFlowList, setWorkFlowList] = useState([]);
  const { mutate: assetMonthlyGScore } = useAssetMonthlyGScore();
  const { mutate: monthlyWorkflowList } = useAssetMonthlyWorkflowList();
  useEffect(() => {
    assetMonthlyGScore(
      {
        manager_asset_id: authData.asset_id,
        operating_asset_id: authData.asset_id,
        flag: '3',
        data_entity_date_1: moment(new Date()).format('YYYY-MM-01'),
      },
      {
        onSuccess: async res => {
          if (res?.length) {
            if (res[0].data_entity_bigint_1) {
              setMonthlyScore(res[0].data_entity_bigint_1);
            }
          }
        },
        onError: async err => {
          console.log(err);
        },
      }
    );
    monthlyWorkflowList(
      {
        flag: 1,
        activity_id: 0,
        form_id: 0,
        form_transaction_id: 0,
        form_transaction_datetime: '',
        start_datetime: moment(new Date()).format('YYYY-MM-01'),
        end_datetime: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
        start_from: 0,
        limit_value: 100,
      },
      {
        onSuccess: async data => {
          if (data?.length) {
            setWorkFlowList(data);
          }
        },
        onError: async err => {
          console.log(err);
        },
      }
    );
  }, []);
  return (
    <Box>
      <Box
        border="none"
        boxShadow="md"
        borderRadius="md"
        mb={2}
        p={2}
        bg={'secondary'}
        color={'black'}
      >
        <Stat>
          <StatLabel>Monthly GScore</StatLabel>
          <StatNumber>
            <Flex d={'flex'} justifyContent={'space-between'}>
              <Text color={'brand.800'} fontSize={'32px'}>
                {monthlyScore}
              </Text>
              <Icon as={VscGraph} h={10} w={10} color="brand.800"/>
            </Flex>
          </StatNumber>
          <StatHelpText>{moment(new Date()).format('MMM YYYY')}</StatHelpText>
        </Stat>
      </Box>
      <Accordion defaultIndex={[0]} allowToggle={true} w="full">
        <AccordionItem border="none" boxShadow="md" borderRadius="md" mb={2}>
          <AccordionButton
            _expanded={{
              bg: 'secondary',
              color: 'brand.900',
              borderRadius: 'md',
            }}
            _focus={{
              border: 'none',
            }}
          >
            <Box flex="1" textAlign="left">
              <Text size="sm" fontWeight="600">
                Workflow List
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} maxH="350px" overflowY="auto">
            <List>
              <ListItem>
                {workFlowList.map(list => {
                  return (
                    <Box
                      key={list.activity_type_id}
                      flex="1"
                      d={'flex'}
                      justifyContent={'space-between'}
                      boxShadow="md"
                      borderRadius="md"
                      mb={2}
                      p={2}
                      fontSize={'16px'}
                    >
                      <Text>{list.activity_type_name}</Text>
                      <Text>{list.field_gamification_score_value}</Text>
                    </Box>
                  );
                })}
              </ListItem>
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
