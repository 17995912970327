import React from 'react'
import Monthly from './Monthly'
import Overall from './Overall'
import { Box, Divider, Heading } from '@chakra-ui/react'
import { useLanguageContext } from '../../../context/locale.context'

export default function GSCore() {
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <Box>
      <Heading fontSize="lg" fontWeight="800" paddingBottom={'10px'}>
        {locale['GScore']}
      </Heading>
      <Overall />
      <Divider />
      <Monthly />
    </Box>
  )
}
